import axios from 'axios'
import { APIHOTS as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/vendedores`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(vendedor) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    Object.keys(vendedor).forEach(key => {
      if(vendedor[key] == null) return
      formData.append(key, vendedor[key])
    })

    return apiClient.post(`${vendedor.id}`, formData, headers)
  },

  cantidad() {
    return apiClient.get('/cantidad')
  },

  eliminar(idVendedor) {
    return apiClient.delete(`/${idVendedor}`)
  },

  guardar(vendedor) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    Object.keys(vendedor).forEach(key => {
      if(vendedor[key] == null) return
      formData.append(key, vendedor[key])
    })

    formData.delete('roles')
    formData.append('roles[]', 4)

    return apiClient.post('', formData, headers)
  },

  vendedoresJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/vendedores.json`, { params: params })
  },

  vendedorJSON(idVendedor,params) {
    params = params || {}
    return apiClient.get(`${idVendedor}.json`, {params:params})
  },

  ventasYComisionesJSON(params){
    params = params || {}
    return apiClient.get('actual/ventas-y-comisiones.json', {params:params})
  }
}