<template>
  <Layout tituloPagina="Hotspot | Vendedores">
    <div class="row" style="margin-bottom: 20px">
      <div class="col-lg-4 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarVendedores()
          "
          :datoini="dato"
          placeholder="Dato del vendedor"
        ></filtrador>
      </div>
      <div class="col-lg-4 col-9 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            <i class="mdi mdi-calendar"></i>&nbsp;Ventas por
          </span>
          <select class="form-select" v-model="ventas_por" @change="refrescarVendedores()">
            <option value="dia">Día</option>
            <option value="personalizado">Personalizado</option>
          </select>
        </div>
      </div>

      <div class="col-lg-4 col-3 text-right">
        <router-link class="btn btn-success" :to="{ name: 'nuevoVendedorHotspot' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo vendedor
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="offset-lg-3 col-lg-3 col-md-6 col-6">
        <label for="">Fecha inicial</label>
        <Datepicker
          ref="fecha_inicial"
          v-model="fecha_inicial"
          placeholder="dd/mm/aaaa"
          :class="'form-control bg-white'"
          :inputFormat="'dd/MM/yyyy'"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-6" v-show="ventas_por != 'dia' || ventas_por == 'personalizado'">
        <label for="">Fecha Final</label>
        <Datepicker
          ref="fecha_final"
          v-model="fecha_final"
          placeholder="dd/mm/aaaa"
          :class="'form-control bg-white'"
          :inputFormat="'dd/MM/yyyy'"
          v-show="ventas_por != 'dia' || ventas_por == 'personalizado'"
        />
      </div>
      <div class="col-lg-3 col-md-6 pt-4 col-6">
        <button class="btn btn-secondary"
          @click="refrescarVendedores()"
        >
          <i class="mdi mdi-refresh"></i>
        </button>
      </div>
    </div>

    <br>

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Listado vendedores
          <small class="font-size-10">
            Aquí puedes ver los vendedores registrados
          </small>
        </h4>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="tblRouters table table-hover align-middle table-nowrap" id="tabla-vendedores">
            <thead>
              <tr>
                <th style="width: 30px">Foto</th>
                <th>Identidad</th>
                <th style="width: 300px">Nombre del vendedor</th>
                <th>Ventas brutas</th>
                <th>Ventas netas</th>
                <th>Comisión</th>
                <th>Fichas vendidas</th>
                <th>Fecha de creación</th>
                <th style=" width: 180px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="vendedor in vendedores" :key="vendedor.id">
                <td>
                  <!-- modificacion del icono del usuario -->
                  <img
                    :src="`${API}/usuarios/${vendedor.id}/foto?_tk=${tk}&ancho=50&alto=50`"
                    class="rounded-circle avatar-sm"
                    v-if="vendedor.id_foto != null"
                  />
                  <img
                    :src="usuarioicon"
                    class="usuarioicon rounded-circle"
                    v-if="vendedor.id_foto == null"
                  />
                  <!-- fin de la modificacion -->
                </td>
                <td>{{ vendedor.identidad }}</td>
                <td>
                  {{ vendedor.nombre }}
                  {{ vendedor.apellido_paterno }}
                  {{ vendedor.apellido_materno }}
                </td>
                <td>
                  {{ formatoMoneda(vendedor.ventas_brutas) }}
                </td>
                <td>
                  {{ formatoMoneda(vendedor.ventas_netas) }}
                </td>
                <td>
                  {{ formatoMoneda(vendedor.comisiones) }}
                </td>
                <td>
                  <span class="badge bg-info bg-gradient font-size-12">
                    {{ vendedor.cantidad_fichas_vendidas ? vendedor.cantidad_fichas_vendidas : 0 }}
                    {{ vendedor.cantidad_fichas_vendidas != 1 ? 'fichas' : 'ficha' }}
                  </span>
                </td>
                <td>
                  {{ formatoFecha(vendedor.created_at) }}
                </td>
                <td class="text-center">
                  <router-link
                    class="btn btn-warning btn-sm"
                    :to="{ name:'edicionVendedorHotspot', params:{ id: vendedor.id } }"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>
                  <button class="btn btn-danger btn-sm" @click="eliminarVendedor(vendedor)">
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarVendedores()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import usuarioicon from '@/assets/img/usuario.png'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import vendedorSrv from '@/services/hotspot/VendedorSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import Datepicker from 'vue3-datepicker'
import accounting from 'accounting/accounting.js'
import moment from 'moment'
import API from '@/API.js'
import Swal from 'sweetalert2'

export default {
  name: 'ListadoVendedores',
  components: {
    Layout,
    Filtrador,
    Paginador,
    Datepicker
  },

  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      usuarioicon: usuarioicon,
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoVendedores.por_pagina') || 10),
      tc: 0, // Total de portales cautivos
      totpag: 0, // Total de páginas
      vendedores: [],
      ventas_por:'dia',
      fecha_inicial: '',
      fecha_final: '',
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)
    if (self.$route.query.ventas_por) self.ventas_por = self.$route.query.ventas_por

    if (self.$route.query.ventas_por == 'dias') {
      self.fecha_inicial = self.$route.query.fecha_inicial ? new Date(moment(self.$route.query.fecha_inicial, 'YYYY-MM-DD').format()) : ''
      self.fecha_final = self.$route.query.fecha_inicial ? new Date(moment(self.$route.query.fecha_inicial, 'YYYY-MM-DD').format()) : ''
    } else {
      self.fecha_inicial = self.$route.query.fecha_inicial ? new Date(moment(self.$route.query.fecha_inicial, 'YYYY-MM-DD').format()) : ''
      self.fecha_final = self.$route.query.fecha_final ? new Date(moment(self.$route.query.fecha_final, 'YYYY-MM-DD').format()) : ''
    }

    //Carga de la fecha inicial del sistema
    if (
      self.fecha_inicial == '' || self.fecha_final == '' ||
      self.fecha_inicial == undefined || self.fecha_final == undefined ||
      self.fecha_inicial == 'Invalid date' || self.fecha_final == 'Invalid date' ||
      self.fecha_inicial == null || self.fecha_final == null
    ) {
      self.fecha_inicial = new Date(moment(self.$store.state.todo.fecha, 'YYYY-MM-DD').format())
      self.fecha_final = new Date(moment(self.$store.state.todo.fecha, 'YYYY-MM-DD').format())
    }

    self.refrescarVendedores()
  },
  watch: {
    fecha_inicial : function(newVal, oldVal) {
      var self = this 
      self.refrescarVendedores()
    },
    fecha_final : function(newVal, oldVal) {
      var self = this 
      self.refrescarVendedores()
    }
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarVendedores({ page: n })
    },
    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoFechaVendedores(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },
    eliminarVendedor: function(vendedor) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Eliminar el vendedor?",
        html: "¿Está seguro de eliminar el vendedor <br><b>"+vendedor.nombre+" "+vendedor.apellido_paterno+" "+vendedor.apellido_materno+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          vendedorSrv.eliminar(vendedor.id).then(response => {
            swal.fire("Eliminado!", "Vendedor "+vendedor.nombre+" "+vendedor.apellido_paterno+" "+vendedor.apellido_materno+" eliminado.", "success");
            self.refrescarVendedores()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar el vendedor "+vendedor.nombre+" "+vendedor.apellido_paterno+" "+vendedor.apellido_materno
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarVendedores: function () {
      var self = this,
        query = {
          dato: self.dato,
          page: self.page,
          epp: self.epp,
          ventas_por : self.ventas_por
        }

      iu.spinner.mostrar('#tabla-vendedores')

      if(self.ventas_por == 'dia'){
        Object.assign(query,{
          fecha_inicial: self.formatoFechaVendedores(self.fecha_inicial)
        })
      } else {
         Object.assign(query,{
          fecha_inicial: self.formatoFechaVendedores(self.fecha_inicial),
          fecha_final: self.formatoFechaVendedores(self.fecha_final)
        })
      }

      this.$router.replace({
        query: query
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato,
        con_ventas_y_comisiones: {}
      }

      if(self.ventas_por == 'dia'){
        if(self.fecha_inicial == null || self.fecha_inicial == '' || self.fecha_inicial == 'Invalid date'){
          return
        }

        Object.assign(params.con_ventas_y_comisiones,{
          fecha_inicial : self.formatoFechaVendedores(self.fecha_inicial),
          fecha_final : self.formatoFechaVendedores(self.fecha_inicial)
        })
      }

      if(self.ventas_por == 'personalizado'){
        if(self.fecha_inicial == null || self.fecha_inicial == '' || self.fecha_inicial == 'Invalid date'){
          iu.msg.warning("La fecha inicial no es válida")
          return
        }

        if(self.fecha_final == null || self.fecha_final == '' || self.fecha_final == 'Invalid date'){
          iu.msg.warning("La fecha final no es válida")
          return
        }

        Object.assign(params.con_ventas_y_comisiones,{
          fecha_inicial : self.formatoFechaVendedores(self.fecha_inicial),
          fecha_final : self.formatoFechaVendedores(self.fecha_final)
        })
      }

      vendedorSrv.vendedoresJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoVendedores.por_pagina', self.epp)
        let page = response.data
        self.vendedores = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarVendedores()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = "No se cargaron los vendedores"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-vendedores')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-vendedores')
  }
}
</script>

<style scoped>
.usuarioicon {
  width: 30px;
  height: 30px;
}
</style>